import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Body from '../components/layout/Body';
import ResponsiveContainer from '../components/layout/ResponsiveContainer';
import ContentWrapper from '../components/layout/ContentWrapper';
import SEO from '../components/SEO';

import H1 from '@rotaready/frecl/build/H1';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';

const BodyText = styled(Text)`
  margin: 20px 0 60px 0;
`;

const NotFoundPage = () => (
  <Body>
    <SEO title="Page not found (404)" />
    <ResponsiveContainer>
      <ContentWrapper>
        <div>
          <H1 uistyle="brand160" text="Page not found" />
          <BodyText text="Sadly the page you're looking for doesn't exist." />
          <Link to="/"><Button uistyle="primary" text="Visit Rotaready" /></Link>
        </div>
      </ContentWrapper>
    </ResponsiveContainer>
  </Body>
);

export default NotFoundPage;
